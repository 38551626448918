import { useState, useEffect, useRef } from "react";
import {
  Modal,
  Select,
  Switch,
  Card,
  Button,
  Row,
  Col,
  Typography,
  Tag,
  Progress,
  Radio,
} from "antd";
import { HistoryOutlined, RightOutlined } from "@ant-design/icons";
import axios from "axios";
import "./style.css";

const { Option } = Select;
const { Text } = Typography;

// interface ClaimData {
//   id: string;
//   title: string;
//   content: string;
//   translatedContent: string;
//   tag: string;
// }

// const claims: ClaimData[] = [
//   {
//     id: 'CL-001286',
//     title: 'The Evidence-Based Physician',
//     content: 'Stay updated on chronic pain management focusing on arthritis through our webinars featuring latest research and best practices for enhancing patient outcomes.',
//     translatedContent: 'En la gestión del dolor crónico en pacientes con artritis severa, mantenerse actualizado con la investigación más reciente es crucial. Únase a nuestros seminarios web donde revisamos estudios recientes y discutimos las mejores prácticas para mejorar los resultados de los pacientes.',
//     tag: 'Evidence-Based',
//   },
//   {
//     id: 'CL-001287',
//     title: 'Tech-Savvy Specialist',
//     content: 'Leverage cutting-edge technology in arthritis treatment. Join our webinars to learn about the latest digital tools and telemedicine practices for improved patient care.',
//     translatedContent: 'Aproveche la tecnología de vanguardia en el tratamiento de la artritis. Únase a nuestros seminarios web para conocer las últimas herramientas digitales y prácticas de telemedicina para mejorar la atención al paciente.',
//     tag: 'Tech-Savvy',
//   },
//   {
//     id: 'CL-001288',
//     title: 'Community-Focused Practitioner',
//     content: 'Engage with your local community to improve arthritis awareness and care. Our webinars offer strategies for community outreach and patient education programs.',
//     translatedContent: 'Involúcrese con su comunidad local para mejorar la conciencia y el cuidado de la artritis. Nuestros seminarios web ofrecen estrategias para programas de alcance comunitario y educación del paciente.',
//     tag: 'Community-Focused',
//   },
//   {
//     id: 'CL-001289',
//     title: 'Holistic Healer',
//     content: 'Explore holistic approaches to arthritis management. Join our webinars to learn about integrating complementary therapies with conventional treatments for comprehensive patient care.',
//     translatedContent: 'Explore enfoques holísticos para el manejo de la artritis. Únase a nuestros seminarios web para aprender sobre la integración de terapias complementarias con tratamientos convencionales para una atención integral del paciente.',
//     tag: 'Holistic',
//   },
// ];

export default function LocalisedNonClaimModal({
  open,
  setOpen,
  withTranslation = false,
}: {
  open: boolean;
  setOpen: Function;
  withTranslation?: boolean;
}) {
  // const [isModalVisible, setIsModalVisible] = useState(false);
  const [showTranslated, setShowTranslated] = useState(true);
  const [selectedClaim, setSelectedClaim] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const showModal = () => setOpen(true);
  const handleCancel = () => setOpen(false);
  const [claimData, setClaimData] = useState<NonClaimData[] | []>([]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let api: string;
        if (withTranslation) {
          api = "https://replicator-dev.devkraft.in/api/translate/elements";
        } else {
          api = "https://replicator-dev.devkraft.in/api/translate/elements";
        }

        const response = await axios.get(api);
        console.log(response.data.data);
        const fetchedData = response?.data?.data?.non_claims || []; // Default to empty array if data is null or undefined
        console.log(fetchedData);
        setClaimData(fetchedData);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);
  const [orignalElement, setOrignalElement] = useState([]);
  const [personalizedElement, setPersonalizedElement] = useState([]);
  const [orignalElementFiltered, setOrignalElementFiltered] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState<string>("all");
  useEffect(() => {
    if (selectedTag !== "all") {
      const filteredData = orignalElement.filter(d => d.type === selectedTag);
      setOrignalElementFiltered(filteredData);
    } else {
      setOrignalElementFiltered(orignalElement);
    }
  }, [selectedTag]);
  console.log("orignalElement", orignalElement);
  function mergeClaims(personalisedData, originalData) {
    // Extract claims from both personalisedData and originalData
    const personalisedClaims = personalisedData.flatMap(item =>
      item.elements.nonclaims.map(claim => ({
        ...claim,
        persona: item.persona,
      })),
    );
    const originalClaims = originalData;

    // Create new array with desired structure
    const newArr = [
      {
        personalisedClaims: personalisedClaims,
        originalClaims: originalClaims,
      },
    ];

    return newArr;
  }

  const processArray = mergeClaims(personalizedElement, orignalElementFiltered);
  console.log("test-->", {
    processArray,
    personalizedElement,
    orignalElement,
  });
  useEffect(() => {
    (async () => {
      try {
        let api: string;
        setIsLoading(true);
        if (withTranslation) {
          api = "https://replicator-dev.devkraft.in/api/personalise/elements?language=Spanish";
        } else {
          api = "https://replicator-dev.devkraft.in/api/personalise/elements?language=English";
        }
        const response = await axios.get(api);

        const res2 = await axios.get("https://replicator-dev.devkraft.in/api/assets/elements");

        // console.log(res2.data.data);

        // console.log("----->", response?.data.data);
        setOrignalElement(res2?.data?.data.non_claims);
        setPersonalizedElement(response?.data?.data);
        setOrignalElementFiltered(res2?.data?.data?.non_claims);
        const tagsSet = new Set();

        res2?.data?.data?.non_claims?.forEach(d => {
          tagsSet.add(d.type);
        });
        const tags = Array.from(tagsSet);
        console.log("tags:", tags);
        setTags(tags);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  // const renderClaim = (claim: NonClaimData, isOriginal: boolean = false) => (
  //   // <Col span={8}>
  //   <Card
  //     style={{
  //       width: "100%",
  //       maxWidth: "300px",
  //       borderRadius: "16px",
  //       boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  //     }}
  //     bodyStyle={{ padding: "24px" }}
  //   >
  //     <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
  //       {!isOriginal && (
  //         <Radio.Group onChange={() => undefined} value={1}>
  //           <Radio value={1} style={{ color: "#6B46C1" }} />
  //         </Radio.Group>
  //       )}
  //       <span style={{ color: "black", fontSize: "14px" }}>
  //         PromoMats ID: <span style={{ color: "#6B46C1" }}>{claim?.id}</span>
  //       </span>
  //     </div>

  //     <div
  //       style={{ marginBottom: "20px", display: "flex", alignItems: "baseline", columnGap: "10px" }}
  //     >
  //       <div>
  //         <Button
  //           type="text"
  //           style={{
  //             display: "flex",
  //             alignItems: "center",
  //             justifyContent: "space-between",
  //             width: "100%",
  //             padding: "8px 16px",
  //             border: "1px solid #E2E8F0",
  //             borderRadius: "8px",
  //             boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
  //           }}
  //         >
  //           <span style={{ fontWeight: "bold", color: "#4A5568" }}>{claim?.type}</span>
  //         </Button>
  //       </div>
  //       <div>
  //         <RightOutlined style={{ color: "#6B46C1", marginLeft: "4px" }} />
  //       </div>
  //     </div>

  //     <div
  //       style={{
  //         marginBottom: "20px",
  //         height: "200px",
  //         overflowY: "auto",
  //         overflowX: "hidden",
  //         wordBreak: "break-word",
  //       }}
  //     >
  //       <p style={{ margin: 0, lineHeight: "1.6", color: "#4A5568" }}>
  //         {/* {showTranslated ? claim.translatedContent : claim.content}
  //          */}
  //         {claim?.text}
  //       </p>
  //     </div>

  //     {!isOriginal && (
  //       <>
  //         <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
  //           <HistoryOutlined style={{ marginRight: "8px", color: "#718096" }} />
  //           <span style={{ color: "#718096" }}>History</span>
  //         </div>

  //         <div
  //           style={{
  //             display: "flex",
  //             alignItems: "center",
  //             justifyContent: "space-between",
  //             marginBottom: "20px",
  //           }}
  //         >
  //           <Tag
  //             color="#FEEBC8"
  //             style={{ color: "#C05621", border: "none", fontWeight: "bold", padding: "4px 8px" }}
  //           >
  //             VVPM
  //           </Tag>
  //           <div style={{ display: "flex", alignItems: "center" }}>
  //             <span style={{ color: "#48BB78", fontWeight: "bold", marginRight: "4px" }}>98%</span>
  //             <span style={{ color: "#718096", fontSize: "14px" }}>Match Rate</span>
  //           </div>
  //         </div>

  //         <Button
  //           type="primary"
  //           style={{
  //             width: "100%",
  //             backgroundColor: "white",
  //             borderColor: "#6B46C1",
  //             color: "#6B46C1",
  //             fontWeight: "bold",
  //             height: "40px",
  //             borderRadius: "8px",
  //           }}
  //         >
  //           Load More Suggestions
  //         </Button>
  //       </>
  //     )}
  //   </Card>
  //   // </Col>
  // );

  const renderClaim = (claim: NonClaimData, isOriginal: boolean = false) => (
    // <Col span={8}>
    <Card
      style={{
        width: "100%",
        maxWidth: "300px",
        borderRadius: "16px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        height: isOriginal ? "91%" : "100%",
        flex: isOriginal ? undefined : 1,
        backgroundColor: isOriginal ? "" : "rgba(240, 240, 240, 1)",
      }}
      bodyStyle={{ padding: "24px", display: "flex", flexDirection: "column", height: "100%" }}
    >
      <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
        {!isOriginal && (
          <Radio.Group onChange={() => undefined} value={1}>
            <Radio value={1} style={{ color: "#6B46C1" }} />
          </Radio.Group>
        )}
        <span style={{ color: "black", fontSize: "14px" }}>
          PromoMats ID:{" "}
          <span style={{ color: "#6B46C1" }}>{isOriginal ? claim?.id : claim.id_}</span>
        </span>
      </div>

      <div
        style={{ marginBottom: "20px", display: "flex", alignItems: "baseline", columnGap: "10px" }}
      >
        <div>
          <Button
            type="text"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              padding: "8px 16px",
              border: "1px solid #E2E8F0",
              borderRadius: "8px",
              boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
            }}
          >
            <span style={{ fontWeight: "bold", color: "#4A5568" }}>{claim?.type}</span>
          </Button>
        </div>
        <div>
          <RightOutlined style={{ color: "#6B46C1", marginLeft: "4px" }} />
        </div>
      </div>

      <div
        style={{
          marginBottom: "20px",
          maxHeight: "200px",
          overflowY: "auto",
          overflowX: "hidden",
          wordBreak: "break-word",
          color: "#4A5568",
          flex: 1,
        }}
      >
        {/* <p style={{ margin: 0, lineHeight: "1.6",  }}> */}
        {/* {showTranslated ? claim.translatedContent : claim.content}
         */}
        {claim?.text}
        {/* </p> */}
      </div>

      {!isOriginal && (
        <>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
            <HistoryOutlined style={{ marginRight: "8px", color: "#718096" }} />
            <span style={{ color: "#718096" }}>History</span>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Tag
              color="#FEEBC8"
              style={{ color: "#C05621", border: "none", fontWeight: "bold", padding: "4px 8px" }}
            >
              VVPM
            </Tag>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ color: "#48BB78", fontWeight: "bold", marginRight: "4px" }}>98%</span>
              <span style={{ color: "#718096", fontSize: "14px" }}>Match Rate</span>
            </div>
          </div>

          <Button
            type="primary"
            style={{
              width: "100%",
              backgroundColor: "white",
              borderColor: "#6B46C1",
              color: "#6B46C1",
              fontWeight: "bold",
              height: "40px",
              borderRadius: "8px",
            }}
          >
            Load More Suggestions
          </Button>
        </>
      )}
    </Card>
    // </Col>
  );
  //scroll logic
  const divRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const handleScroll = (sourceRef: HTMLDivElement | null) => {
      if (!sourceRef) return;

      divRefs.current.forEach(targetRef => {
        if (targetRef && targetRef !== sourceRef) {
          targetRef.scrollTop = sourceRef.scrollTop;
          targetRef.scrollLeft = sourceRef.scrollLeft;
        }
      });
    };

    const divs = divRefs.current;

    divs.forEach(divRef => {
      if (divRef) {
        divRef.addEventListener("scroll", () => handleScroll(divRef));
      }
    });

    return () => {
      divs.forEach(divRef => {
        if (divRef) {
          divRef.removeEventListener("scroll", () => handleScroll(divRef));
        }
      });
    };
  }, [divRefs, personalizedElement, orignalElement]);
  const handleChange = (value: string) => {
    setSelectedTag(value);
    // You can perform any action here based on the selected value
  };

  return (
    <>
      {/* <Button onClick={showModal}>Open Localised Non Claim Modal</Button> */}
      <Modal
        title={
          withTranslation
            ? "Localised Non Claim with Translation and Personalisation"
            : "Localised Non Claim without Translation and Personalisation"
        }
        open={open}
        onCancel={handleCancel}
        style={{ width: "1200px" }}
        // width={1200}
        className="fullscreen-modal localModal"
        centered
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="save" type="primary">
            Save Changes
          </Button>,
        ]}
      >
        <Row gutter={16} style={{ marginBottom: "16px" }}>
          <Col span={12} style={{ display: "flex", gap: "20px" }}>
            <Select defaultValue="all" style={{ width: "50%" }} onChange={handleChange}>
              <Option value="all">All</Option>
              {tags?.map(tag => {
                return <Option value={tag}>{tag}</Option>;
              })}
            </Select>
            {withTranslation ? (
              <Select defaultValue="spanish" style={{ width: "50%" }}>
                <Option value="spanish">Language: Spanish</Option>
              </Select>
            ) : (
              <></>
            )}
          </Col>
        </Row>

        <Row align="middle" justify="space-between" style={{ marginBottom: "20px" }}>
          <Col span={6}>
            <div>
              <Text strong>
                Original Element{" "}
                <span style={{ fontWeight: 400, color: "gray", fontSize: "14px" }}>
                  (Market : <span style={{ fontWeight: 500, color: "black" }}>US</span>)
                </span>
              </Text>
            </div>
          </Col>
          <Col span={18}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Text strong>
                  Equivalent Elements{" "}
                  <span style={{ fontWeight: 400, color: "gray", fontSize: "14px" }}>
                    (Market : <span style={{ fontWeight: 500, color: "black" }}>Spain</span>)
                  </span>
                </Text>
              </div>
              {/* {withTranslation ? (
                <div style={{ display: "flex", columnGap: "10px" }}>
                  <Switch
                    // checkedChildren="Show Translated"
                    // unCheckedChildren="Show Original"
                    checked={showTranslated}
                    onChange={setShowTranslated}
                  />
                  <div>Show Translated</div>
                </div>
              ) : (
                <></>
              )} */}
            </div>
          </Col>
        </Row>
        {processArray?.[0].originalClaims &&
          processArray?.[0]?.originalClaims.map((claim: NonClaimData, claimIndex: number) => {
            const matchingPersonalisedClaims: NonClaimData[] =
              processArray[0].personalisedClaims.filter(
                (personalisedClaim: NonClaimData) => personalisedClaim.id === claim.id,
              );

            console.log("testp", matchingPersonalisedClaims);

            return (
              <Row gutter={16}>
                <Col xs={24} md={6} style={{ padding: "16px 0" }}>
                  {/* <div
                      style={{
                        marginBottom: "12px",
                        display: "block",
                        width: "fit-content",
                        borderRadius: "1000px",
                        padding: "3px",
                      }}
                    ></div> */}
                  <Tag
                    color="purple"
                    style={{
                      marginBottom: "12px",
                      display: "block",
                      width: "fit-content",
                      borderRadius: "1000px",
                      padding: "3px",
                      visibility: "hidden",
                    }}
                  >
                    tag
                  </Tag>
                  {renderClaim(claim, true)}
                </Col>

                <Col xs={24} md={18}>
                  <div
                    ref={el => (divRefs.current[claimIndex] = el)}
                    style={{
                      overflowX: "scroll",
                      /* whiteSpace: 'nowrap', */ padding: "16px 0",
                      display: "flex",
                      // gap: "0px",
                      width: "100%",
                    }}
                  >
                    {/* <Row gutter={[4, 4]}> */}
                    {matchingPersonalisedClaims?.map((data, index) => {
                      // <div key={index} style={{ width: "300px", marginRight: "16px" }}>

                      return (
                        <Col span={8} style={{ display: "flex", flexDirection: "column" }}>
                          <Tag
                            color="purple"
                            style={{
                              marginBottom: "12px",
                              display: "block",
                              width: "fit-content",
                              borderRadius: "1000px",
                              padding: "3px",
                            }}
                          >
                            {data?.persona?.split("_").join(" ")}
                          </Tag>
                          {renderClaim(data)}
                        </Col>
                      );
                      // </div>
                    })}
                    {/* </Row> */}
                  </div>
                </Col>
              </Row>
            );
          })}
      </Modal>
    </>
  );
}
