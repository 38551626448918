import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { PDFViewer } from "../../pdf/PDFViewer";
import { AssetItem } from "../../../resources/types";
import "./AssetViewer.css";

interface Asset {
  asset_id: string;
  thumbnail: string;
  url: string;
  asset_name: string;
}
export const AssetViewer: React.FC<{
  title?: string;
  open: boolean;
  setOpen: Function;
  item: Asset | AssetItem;
  assetsList: Asset[] | AssetItem[];
}> = ({ title, open, setOpen, item, assetsList }) => {
  const [selectedItem, setSelectedItem] = useState<Asset | AssetItem>();

  const oncancel = () => {
    setOpen(false);
  };
  const onOk = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (item) {
      setSelectedItem(item);
    }
  }, [item]);

  return (
    <Modal
      open={open}
      className="fullscreen-modal asset-viewer"
      title={(title ? title + " " : "") + "Assets Preview"}
      onCancel={oncancel}
      onOk={onOk}
      onClose={oncancel}
    >
      <div style={{ display: "flex", gap: "10px" }}>
        <PDFViewer
          url={
            selectedItem?.url ||
            "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/RTE_US_English.pdf"
          }
          className="asset-pdf-viewer"
        />
        <div style={{ flex: 1, height: "calc(100vh - 160px)", overflowY: "auto" }}>
          {assetsList.map(item => (
            <img
              key={item.asset_id}
              src={item.thumbnail}
              width={"100%"}
              style={{
                border:
                  selectedItem?.asset_id === item.asset_id
                    ? "2px solid #5d2cc9"
                    : "2px solid transparent",
                borderRadius: "5px",
              }}
              onClick={() => setSelectedItem(item)}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};
