import React, { useEffect, useRef } from "react";
import { Modal, Switch, Button, Typography, Space, Row, Col } from "antd";
import { CloseOutlined, CheckCircleFilled } from "@ant-design/icons";
import VVPM from "../component/Tags/VVPM";
import Precentage from "../component/Tags/Precentage";
import AIGeneratedTag from "../component/Tags/AIGeneratedTag";
import axios from "axios";
// import DummyPdf from '../assets/test.pdf';
import { Document, Page, pdfjs } from "react-pdf";
import { PDFViewer } from "../../pages/Content Replicator/components/pdf/PDFViewer";
import { useSyncedScroll } from "../../hooks/useSyncedScroll";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const { Title, Text } = Typography;

interface LocalizedAssetModalProps {
  open: boolean;
  setOpen?: Function;
  withTranslation?: boolean;
}

const Asset: React.FC<LocalizedAssetModalProps> = ({ open, setOpen, withTranslation = false }) => {
  const thumbnails = [...Array(10)].map(
    (_, index) => `https://picsum.photos/100/100?random=${index}`,
  );
  const [selectedImage, setSelectedImage] = React.useState<string>("https://picsum.photos/400/400");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<AssetData>({
    asset_id: "",
    asset_name: "",
    thumbnail: "",
    url: "",
  });

  const { firstRef, secondRef } = useSyncedScroll(open);

  const onCancel = () => {
    setOpen?.(false);
  };
  const onSave = () => {
    setOpen?.(false);
  };
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let api;
        if (withTranslation) {
          api = "https://replicator-dev.devkraft.in/api/translate/1adFwef";
        } else {
          api = "https://replicator-dev.devkraft.in/api/localise/1adFwef";
        }

        const response = await axios.get(api);
        console.log(response.data.data);
        const fetchedData = response?.data?.data?.[0] || {};
        console.log("line number 57", fetchedData);
        setData(fetchedData);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      width={1200}
      style={{ top: 20 }}
      closeIcon={<CloseOutlined style={{ fontSize: "16px", color: "#000" }} />}
    >
      <div style={{ padding: "20px" }}>
        <Title level={3} style={{ marginBottom: "20px" }}>
          Assembly:
          <span>
            <Text
              style={{
                fontSize: "22px",
                fontWeight: 400,
              }}
            >
              {withTranslation
                ? "Localised Asset With Translation"
                : "Localised Asset Without Translation"}
            </Text>
          </span>
        </Title>
        <Row gutter={16}>
          <Col span={12}>
            <div
              style={{
                height: "600px",
                overflow: "auto",
                backgroundColor: "#E8EAF0",
                padding: "16px",
                borderRadius: "8px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              }}
              ref={firstRef}
            >
              <Row style={{ marginBottom: "1rem" }}>
                <b>
                  Original Asset{" "}
                  <span style={{ fontWeight: 400, color: "gray", fontSize: "14px" }}>
                    (Market : <span style={{ fontWeight: 500, color: "black" }}>US</span>)
                  </span>
                </b>
              </Row>
              {/* <Document
                file={{
                  url: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/RTE_US_English.pdf",
                }}
              >
                <Page pageNumber={1} />
              </Document> */}
              <PDFViewer url="https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/RTE_US_English.pdf" />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                height: "600px",
                overflow: "auto",
                padding: "16px",
                borderRadius: "8px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              }}
              ref={secondRef}
            >
              <Row style={{ marginBottom: "1rem" }}>
                <b>
                  {withTranslation ? "Translated Asset" : "Localised Asset"}{" "}
                  <span style={{ fontWeight: 400, color: "gray", fontSize: "14px" }}>
                    (Market : <span style={{ fontWeight: 500, color: "black" }}>Spain</span>)
                  </span>
                </b>
              </Row>
              {/* <img
                src={selectedImage}
                alt="Personalized Asset"
                style={{
                  width: "100%",
                  height: "460px",
                  objectFit: "cover",
                  borderRadius: 4,
                  cursor: "pointer",
                }}

              /> */}

              {/* <Document
                file={{
                  url: data?.url,
                }}
              >
                <Page pageNumber={1} />
              </Document> */}
              <PDFViewer url={data?.url} />
            </div>
          </Col>
        </Row>
        <Space style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button type="primary" onClick={onSave}>
            Save
          </Button>
        </Space>
      </div>
    </Modal>
  );
};

export default Asset;
