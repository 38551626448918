import DefaultLayout from "../layouts/DefaultLayout";
import Dashboard from "../pages/Dashboard/Dashboard";
import ROUTE_PATHS from "./routePaths";
import { ContentReplicator } from "../pages/Content Replicator/ContentReplicator";
import LoginScreen from "../pages/Login/LoginScreen";
import WelcomeScreen from "../ss-dev/slide1/WelcomeScreen";
import { ContentReplicatorModule } from "../pages/Content Replicator/ContentReplicatorModule";

type RouteConfig = {
  path: string;
  element: React.ComponentType<any>;
  layout?: React.ComponentType<any>;
  isProtected?: boolean;
};

const routes: RouteConfig[] = [
  {
    path: ROUTE_PATHS.DASHBOARD,
    element: WelcomeScreen,
    layout: DefaultLayout,
    isProtected: true,
  },
  {
    path: ROUTE_PATHS.LOGIN,
    element: LoginScreen,
  },
  {
    path: ROUTE_PATHS.CONTENT_REPLICATOR,
    element: ContentReplicatorModule, //ContentReplicator,
    layout: DefaultLayout,
    isProtected: true,
  },
];

export default routes;
