import React from "react";
import { Input, Card, Row, Col, Typography } from "antd";
import {
  AudioOutlined,
  SendOutlined,
  CopyOutlined,
  EditOutlined,
  TeamOutlined,
  FileTextOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import FeatureCards from "./Cards";
const { Title, Text } = Typography;

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

export default function WelcomeScreen() {
  const backgroundStyle: React.CSSProperties = {
    backgroundImage: `url('/background.jpg')`, // Assuming the image is in the public directory
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "330px",
    padding: "50px",
  };
  const inputStyle: React.CSSProperties = {
    width: "100%",
    maxWidth: "600px",
    borderRadius: "10px",
    padding: "12px 16px",
    fontSize: "16px",
    boxShadow: "0 0 20px rgba(198, 193, 215, 0.3)",
    border: "10px solid #e6e1ff",
  };

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        overflowX: "auto" /* paddingBottom: "80px" */,
        paddingBottom: "42px",
      }}
    >
      <div style={backgroundStyle}>
        <Title level={2} style={{ color: "white", marginBottom: "8px" }}>
          Welcome Suresh!
        </Title>
        <Text style={{ color: "white", marginBottom: "24px", display: "block" }}>
          This is your one stop shop for Compliance Ready Assets
        </Text>

        <Input
          placeholder="What would you like to create today? You can try saying 'I want to create an asset'"
          suffix={
            <>
              <AudioOutlined style={{ color: "rgba(0,0,0,.45)", fontSize: "18px" }} />
              <SendOutlined style={{ color: "#4F2AD0", fontSize: "18px" }} />
            </>
          }
          style={inputStyle}
        />
      </div>

      <div
        style={{
          marginTop: "-60px",
          // position: "absolute", // lg:absolute
          gap: "10px", // gap-10
          justifyContent: "center", // justify-center
          display: "flex", // flex
          flexWrap: "wrap", // flex-wrap
          width: "100%", // w-full
          bottom: "37%", // lg:bottom-[0]
          left: "50%", // lg:left-[50%]
          // transform: "translate(-50%, 50%)", // lg:translate-x-[-50%] lg:translate-y-[50%]
          color: "black", // text-black
          marginBottom: "0", // lg:mb-0
          padding: "0 3px", // px-3
        }}
      >
        <FeatureCards />
      </div>

      {/* Footer */}
      <div
        style={{
          position: "fixed",
          bottom: "0px", // Keep footer pinned to the bottom
          left: "50%",
          transform: "translateX(-50%)",
          textAlign: "center",
          zIndex: 1000, // Ensure footer is on top
          backgroundColor: "white", // Add a background color for contrast
          width: "100%", // Make sure the footer spans the width of the page
          padding: "10px 0", // Compact footer size
          // borderTop: '1px solid #ccc', // Optional border for separation
        }}
      >
        <Text style={{ color: "black", opacity: 0.7 }}>Powered By NEXT AI</Text>
        <Text style={{ color: "black", opacity: 0.7, marginLeft: "16px" }}>
          © 2024 Indegene. All Rights Reserved.
        </Text>
      </div>
    </div>
  );
}
