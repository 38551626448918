import { useState } from "react";
import { ContentReplicator } from "./ContentReplicator";
import ReplicatorAIWelcome from "../../ss-dev/slide2/ReplicatorAIWelcome";
import Index from "../../ss-dev/slide2/Page";
import { ASSETS } from "../../assets/assetsSrcMap";
import { useAppDispatch } from "../../store/hooks";
import { resetCRStates } from "../../store/slices/contentReplicatorSlice";

export const ContentReplicatorModule = () => {
  const [hasStarted, setHasStarted] = useState(false);
  const dispatch = useAppDispatch();

  const handleStart = () => {
    dispatch(resetCRStates());
    setHasStarted(true);
  };

  if (hasStarted) return <ContentReplicator />;
  else
    return (
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
        <div
          style={{
            fontSize: "30px",
            fontWeight: 700,
            display: "flex",
            alignItems: "center",
            gap: "5px",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          <img src={ASSETS.CONTENT_REPLICATOR_MODULE_ICON} height={30} width={30} />
          Content Replicator
        </div>
        <Index onStart={handleStart} />
      </div>
    );
};
