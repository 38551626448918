import React, { useEffect, useState } from "react";
import { Modal, Button, Typography, Tag, Tooltip, Row, Col, Skeleton } from "antd";
import { RightOutlined, LikeOutlined, DislikeOutlined, EditOutlined } from "@ant-design/icons";
import { Select, Slider, Checkbox, Divider, Tabs } from "antd";
import {
  LeftOutlined,
  UserOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { BsFillMicFill, BsSendFill, BsPaperclip, BsGearFill } from "react-icons/bs";
import "./style.css";
import Orignal from "../assets/Original.webp";
const { Title, Text } = Typography;

interface ImageData {
  id: number;
  src: string;
  alt: string;
  tag: string;
  tagColor: string;
  matchRate?: number;
  label?: string;
  promoMatsId: string;
  parsona: string;
}

const images: ImageData[] = [
  {
    id: 1,
    src: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/persona1_alt.jpg",
    alt: "Man in suit with shoulder pain",
    tag: "AI Generated",
    tagColor: "purple",
    promoMatsId: "CP-002025",
    parsona: "Early Adopter",
  },
  {
    id: 2,
    src: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/persona3.jpg",
    alt: "Bearded man with shoulder pain",
    tag: "AI Generated",
    tagColor: "purple",
    matchRate: 90,
    // label: "Tech-Savvy Specialist",
    promoMatsId: "CP-003225",
    parsona: "Evidence Focused",
  },
  {
    id: 3,
    src: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/persona2.jpg",
    alt: "Shirtless man with shoulder pain",
    tag: "AI Generated",
    tagColor: "purple",
    matchRate: 65,
    promoMatsId: "CP-002065",
    parsona: "Patient Centric",
  },
  {
    id: 4,
    src: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/persona1_alt.jpg",
    alt: "Person touching their shoulder",
    tag: "VVPM",
    tagColor: "orange",
    // label: "Community-Focus",
    promoMatsId: "CP-002080",
    parsona: "Experience Focused",
  },
];

export default function Component({ open, setOpen }: { open: boolean; setOpen?: Function }) {
  // const [isModalVisible, setIsModalVisible] = useState(false);

  // const showModal = () => setIsModalVisible(true);
  // const handleCancel = () => setIsModalVisible(false);
  const showModal = () => setOpen?.(true);
  const [modalPopup, setModalPopup] = useState(false);
  const handleCancel = () => {
    setOpen?.(false);
    setMiniImg("");
    setModalPopup(false);
    setAiGenTabVisible(false);
    setEnterdPrompt("");
    setActiveKey("VVPM");
  };
  const iconStyle: React.CSSProperties = {
    color: "white",
    fontSize: "20px",
    margin: "0 6px",
  };

  const [idImg, setIdImg] = useState(0);
  const [activeKey, setActiveKey] = useState("VVPM");

  const vvpmImages = [
    "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/localization_without_Trans.jpg?img=CP-009225A",
    "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/AI_2.jpg?img=CP-008625A",
    "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/3.jpg?img=CP-000725A",
  ];
  const aiGeneratedImages = [
    "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/AI_1.jpg?img=CP-009225A",
    "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/1.jpg?img=CP-008625A",
    "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/2.jpg?img=CP-000725A",
  ];

  const [miniImg, setMiniImg] = useState("");
  console.log(miniImg);

  const renderImages = (images: string[], isVVPM: boolean) => (
    <>
      {isLoader && !isVVPM && <Skeleton active />}

      {(!isLoader || isVVPM) && (
        <Row gutter={[8, 8]}>
          {images.map((src, index) => (
            <Col span={8} key={index}>
              <div
                style={{
                  position: "relative",
                  border: isVVPM && activeKey === "VVPM" ? "none" : "none",
                  borderRadius: 8,
                  overflow: "hidden",
                }}
              >
                <img
                  src={src}
                  alt={`Alternative ${index + 1}`}
                  style={{ width: "100%", display: "block", cursor: "pointer" }}
                  onClick={() => setMiniImg(src)}
                />
                {src === miniImg && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white
                      border: "2px solid #6752e9", // Blue border
                      borderRadius: 4,
                      boxSizing: "border-box",
                    }}
                  >
                    <CheckCircleFilled
                      style={{
                        position: "absolute",
                        left: 4,
                        right: 4,
                        color: "#6752e9",
                        fontSize: 24, // Adjust size as needed
                      }}
                    />
                  </div>
                )}
                {!isVVPM && (
                  <Text
                    style={{
                      position: "absolute",
                      bottom: 4,
                      right: 4,
                      backgroundColor: "rgba(0,0,0,0.5)",
                      color: "white",
                      padding: "2px 4px",
                      borderRadius: 4,
                      fontSize: 12,
                    }}
                  >
                    92% Match Rate
                  </Text>
                )}
              </div>
            </Col>
          ))}
        </Row>
      )}
    </>
  );

  console.log(modalPopup);
  const onClickEdit = (id: number) => {
    setModalPopup(true);
    setIdImg(id);
  };

  const [enteredPrompt, setEnterdPrompt] = useState("");
  const [aiGenTabVisible, setAiGenTabVisible] = useState(false);
  const handelBigPopup = () => {
    setMiniImg("");
    setModalPopup(false);
    setAiGenTabVisible(false);
    setEnterdPrompt("");
    setActiveKey("VVPM");
  };

  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    if (isLoader) {
      setTimeout(() => {
        setIsLoader(false);
      }, 3000);
    }
  }, [isLoader]);
  return (
    <>
      {/* <Button onClick={showModal}>Open Personalisation Modal</Button> */}
      <Modal
        title="Personalisation: Images and Graphic Contents"
        // visible={isModalVisible}
        open={open}
        onCancel={modalPopup ? handelBigPopup : handleCancel}
        width={1200}
        footer={[
          <Button key="cancel" onClick={modalPopup ? handelBigPopup : handleCancel}>
            Cancel
          </Button>,
          <Button key="save" type="primary">
            Save
          </Button>,
        ]}
        centered
        className="personalisation-modal"
      >
        {modalPopup ? (
          <>
            <Row gutter={24}>
              <Col span={6}>
                <div style={{ background: "#f0f2f5", padding: 16, borderRadius: 8 }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      {[
                        { label: "Category", value: "Original Element" },
                        { label: "Image", value: "https://picsum.photos/400/400" },
                        { label: "Personas", value: "----" },
                        { label: "Market", value: "US" },
                        { label: "Image ID", value: "CP-001212", style: { color: "#6752e9" } },
                        { label: "Image Type", value: "Photo of Person" },
                        { label: "Age", value: "60 Years" },
                        { label: "Gender", value: "Male" },
                        { label: "Ethnicity", value: "White" },
                        { label: "Wearing Glasses", value: "No" },
                        { label: "Emotions", value: "No" },
                        { label: "Background", value: "N/A" },
                      ].map((item, index) => (
                        <React.Fragment key={index}>
                          {item.label === "Image" ? (
                            <tr>
                              <td
                                style={{
                                  padding: "8px 0",
                                  color: "#8c8c8c",
                                  fontWeight: "bold",
                                  width: "40%",
                                }}
                              >
                                {item.label}
                              </td>
                              <td style={{ padding: "8px 0", ...item.style }}>
                                <img
                                  src={Orignal}
                                  style={{ width: "100%", borderRadius: 4, cursor: "pointer" }}
                                />
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td
                                style={{
                                  padding: "8px 0",
                                  color: "#8c8c8c",
                                  fontWeight: "bold",
                                  width: "40%",
                                }}
                              >
                                {item.label}
                              </td>
                              <td style={{ padding: "8px 0", ...item.style }}>{item.value}</td>
                            </tr>
                          )}
                          {index !== 10 && (
                            <tr>
                              <td colSpan={2}>
                                <Divider style={{ margin: "4px 0" }} />
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Col>
              <Col span={10}>
                <div
                  style={{ marginBottom: 16 }}
                  onClick={() => {
                    setMiniImg("");

                    setModalPopup(false);
                    setAiGenTabVisible(false);
                    setEnterdPrompt("");
                    setActiveKey("VVPM");
                  }}
                >
                  <Button icon={<LeftOutlined />} type="text">
                    Image Regeneration
                  </Button>
                </div>
                <div style={{ position: "relative" }}>
                  <img
                    src={miniImg ? miniImg.split("?")[0] : images[idImg - 1]?.src}
                    alt="Generated Image"
                    style={{ width: "100%", borderRadius: 8, marginBottom: 16 }}
                  />
                  {!aiGeneratedImages.includes(miniImg) &&
                    (miniImg ? (
                      <Text
                        style={{
                          position: "absolute",
                          bottom: "29px",
                          right: "10px",
                          backgroundColor: "rgba(0,0,0,0.5)",
                          color: "white",
                          padding: "2px 4px",
                          borderRadius: 4,
                          fontSize: 12,
                        }}
                      >
                        PromoMats ID:<span style={{ color: "white" }}>{miniImg.split("=")[1]}</span>
                      </Text>
                    ) : (
                      <Text
                        style={{
                          position: "absolute",
                          bottom: "29px",
                          right: "10px",
                          backgroundColor: "rgba(0,0,0,0.5)",
                          color: "white",
                          padding: "2px 4px",
                          borderRadius: 4,
                          fontSize: 12,
                        }}
                      >
                        PromoMats ID:
                        <span style={{ color: "white" }}>{images[idImg - 1]?.promoMatsId}</span>
                      </Text>
                    ))}
                </div>

                <Title level={5}>The Evidence-Based Physician</Title>
                <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
                  <Tag color="#f0f2ff" style={{ color: "#6752e9", borderColor: "#6752e9" }}>
                    AI Generated
                  </Tag>
                  <Text style={{ marginLeft: 8 }}>90% Match Rate</Text>
                  <Button type="link" style={{ marginLeft: "auto" }}>
                    Hide Suggestions
                  </Button>
                </div>
                <Tabs
                  activeKey={activeKey}
                  onChange={setActiveKey}
                  items={[
                    {
                      key: "VVPM",
                      label: (
                        <span style={{ color: activeKey === "VVPM" ? "#6752e9" : "inherit" }}>
                          VVPM
                        </span>
                      ),
                      children: renderImages(vvpmImages, true),
                    },
                    {
                      ...(aiGenTabVisible && {
                        key: "AI Generated",
                        label: (
                          <span
                            style={{ color: activeKey === "AI Generated" ? "#6752e9" : "inherit" }}
                          >
                            AI Generated
                          </span>
                        ),
                        children: renderImages(aiGeneratedImages, false),
                      }),
                    },
                  ]}
                  tabBarStyle={{
                    marginBottom: 16,
                    borderBottom: "1px solid #d9d9d9",
                  }}
                />
              </Col>
              <Col
                span={8}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "space-between",
                }}
              >
                <div style={{ padding: "0 16px" }}>
                  <Title level={5}>General Settings</Title>
                  <Text strong>Aspect Ratio</Text>
                  <Select
                    defaultValue="square"
                    style={{ width: "100%", marginBottom: 16 }}
                    suffixIcon={<span style={{ color: "#6752e9" }}>▼</span>}
                  >
                    <Select.Option value="square">Rectangle (4:3)</Select.Option>
                  </Select>
                  <Text strong>Contrast & Exposure</Text>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #d9d9d9",
                      borderRadius: 8,
                      padding: "8px 12px",
                      marginBottom: 16,
                    }}
                  >
                    <BsGearFill style={{ color: "#000000" }} />
                    <Slider
                      defaultValue={30}
                      style={{ flex: 1, margin: "0 8px" }}
                      trackStyle={{ backgroundColor: "#6752e9" }}
                      handleStyle={{ borderColor: "#6752e9" }}
                    />
                    <BsGearFill style={{ color: "#000000" }} />
                  </div>
                  <Text strong>Output Presences</Text>
                  {[
                    {
                      icon: <UserOutlined />,
                      text: "Target Persona: The Evidence-Based Physician",
                    },
                    { icon: <GlobalOutlined />, text: "Channel: Emailer" },
                    { icon: <GlobalOutlined />, text: "Market*: Spain" },
                  ].map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#f5f5f5",
                        padding: "8px 12px",
                        borderRadius: 8,
                        marginBottom: 8,
                      }}
                    >
                      {item.icon}
                      <span style={{ marginLeft: 8 }}>{item.text}</span>
                    </div>
                  ))}
                  <Text strong style={{ display: "block", marginTop: 16, marginBottom: 8 }}>
                    Prompts
                  </Text>
                  <div style={{ display: "flex", flexWrap: "wrap", gap: 8, marginBottom: 16 }}>
                    {[
                      "Cheerful elderly man running energetically in a park.",
                      "An elderly man enjoying a walk with a big smile.",
                      "smiling old man running with enthusiasm.",
                    ].map((tag, index) => (
                      <Tag
                        key={index}
                        style={{
                          borderRadius: 16,
                          padding: "4px 12px",
                          border: "1px solid #6752e9",
                          backgroundColor: "white",
                          color: "#666",
                        }}
                      >
                        {tag}
                      </Tag>
                    ))}
                  </div>
                </div>
                <div style={{ position: "relative", textAlign: "end", padding: "0px 16px" }}>
                  {/* <div style={{ marginBottom: 20 }}>
                      <Text type="secondary" style={{ fontSize: 12 }}>
                        Additional cost accrued for iterations
                        <Text style={{ color: "#52c41a", marginLeft: 8 }}>$02/-</Text>
                        <Tooltip title="Info">
                          <InfoCircleOutlined
                            style={{ marginLeft: 4, fontSize: 12, color: "#8c8c8c" }}
                          />
                        </Tooltip>
                      </Text>
                    </div> */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #d9d9d9",
                      borderRadius: 8,
                      padding: "8px 12px",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    }}
                  >
                    <BsPaperclip style={{ color: "#bfbfbf", marginRight: 8, cursor: "pointer" }} />
                    <input
                      placeholder="Enter your prompt here"
                      style={{
                        flex: 1,
                        border: "none",
                        outline: "none",
                      }}
                      value={enteredPrompt}
                      onChange={e => setEnterdPrompt(e.target.value)}
                    />
                    <BsFillMicFill
                      style={{ color: "#bfbfbf", marginRight: 8, cursor: "pointer" }}
                    />
                    <BsSendFill
                      style={{ color: "#6752e9", cursor: "pointer" }}
                      onClick={() => {
                        if (enteredPrompt) {
                          setEnterdPrompt("");
                          setAiGenTabVisible(true);
                          setActiveKey("AI Generated");
                          setIsLoader(true);
                        }
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <Row gutter={16}>
            <Col span={4}>
              <Text strong>Category</Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  height: "100%",
                }}
              >
                <div style={{ marginTop: "2.5rem" }}>
                  <Text>Image</Text>
                </div>
                {/* <div style={{ height: "", display: "flex", alignItems: "flex-end" }}> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: ".5rem",
                    marginTop: "2.5rem",
                  }}
                >
                  <div>
                    <Text>Image ID</Text>
                  </div>
                  <div>Image Type</div>
                </div>
              </div>

              {/* </div> */}
            </Col>
            <Col span={6}>
              <Text strong>
                Original Image{" "}
                <span style={{ fontWeight: 400, color: "gray", fontSize: "14px" }}>
                  (Market : <span style={{ fontWeight: 500, color: "black" }}>US</span>)
                </span>
              </Text>
              <div style={{ marginTop: 16, display: "flex", overflowX: "auto" }}>
                <div style={{ minWidth: 250, marginRight: "16px", maxWidth: 250 }}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                      }}
                    >
                      <Tag
                        color={"red"}
                        style={{ marginBottom: "1rem", top: 8, left: 8, visibility: "hidden" }}
                      >
                        {"image.tag"}
                      </Tag>
                      {/* {image.matchRate && (
                      <Tag color="green" style={{ top: 8, right: 8 }}>
                        {image.matchRate}% Match Rate
                      </Tag>
                    )} */}
                    </div>
                    <div className="image-container original-image">
                      <img src={Orignal} alt={"original"} />
                    </div>
                  </div>
                  <div>
                    <div>
                      <Text>
                        PromoMats ID: <span style={{ color: "#5B4394" }}>{"CP-001212 "}</span>{" "}
                      </Text>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", marginTop: "0.4rem" }}>
                      <span
                        style={{
                          border: "1px solid gray",
                          padding: "3px",
                          borderRadius: "5px",
                          fontSize: "12px",
                        }}
                      >
                        Image of a Person
                      </span>
                      <RightOutlined style={{ marginLeft: 8 }} />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={14}>
              <Text strong>
                Equivalent Images{" "}
                <span style={{ fontWeight: 400, color: "gray", fontSize: "14px" }}>
                  (Market : <span style={{ fontWeight: 500, color: "black" }}>Spain</span>)
                </span>
              </Text>
              <div style={{ marginTop: 16, display: "flex", overflowX: "auto" }}>
                {images.map((image, index) => (
                  <div key={index} style={{ minWidth: 250, marginRight: 16 }}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <Tag
                          color={image.tagColor}
                          style={{ marginBottom: "1rem", top: 8, left: 8 }}
                        >
                          {image.tag}
                        </Tag>
                        {image.matchRate && (
                          <Tag color="green" style={{ top: 8, right: 8 }}>
                            {image.matchRate}% Match Rate
                          </Tag>
                        )}
                      </div>
                      <div className="image-container">
                        <img src={image.src} alt={image.alt} />
                        <Text
                          style={{
                            position: "absolute",
                            bottom: 5.5,
                            right: 4,
                            backgroundColor: "rgba(0,0,0,0.5)",
                            color: "white",
                            padding: "2px 4px",
                            borderRadius: 4,
                            fontSize: 12,
                          }}
                        >
                          {image?.parsona}
                        </Text>
                        <div className="overlay2"></div>
                        <div className="icon-container3">
                          <LikeOutlined style={iconStyle} />
                          <DislikeOutlined style={iconStyle} />
                        </div>
                        <div className="icon-container2" onClick={() => onClickEdit(image.id)}>
                          <EditOutlined style={iconStyle} />
                        </div>
                        {image.label && (
                          <Text
                            style={{
                              position: "absolute",
                              bottom: 8,
                              left: 8,
                              color: "white",
                              backgroundColor: "rgba(0,0,0,0.5)",
                              padding: "2px 4px",
                              borderRadius: 4,
                            }}
                          >
                            {image.label}
                          </Text>
                        )}
                      </div>
                    </div>
                    <div>
                      <div>
                        <Text>
                          PromoMats ID:{" "}
                          <span
                            style={{ color: image.promoMatsId === "N/A" ? "black" : "#5B4394" }}
                          >
                            {image.promoMatsId}
                          </span>{" "}
                        </Text>
                      </div>
                      <div style={{ display: "flex", alignItems: "center", marginTop: "0.4rem" }}>
                        <span
                          style={{
                            border: "1px solid gray",
                            padding: "3px",
                            borderRadius: "5px",
                            fontSize: "12px",
                          }}
                        >
                          Image of a Person
                        </span>
                        <RightOutlined style={{ marginLeft: 8 }} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        )}
      </Modal>
    </>
  );
}
