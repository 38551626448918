import { useState } from "react";
import { StepWiseProgress } from "../../components/Conversation/Loaders/StepWiseProgress";
import ImageModal from "../../../../ss-dev/slide35/ImageModal";
import AssemblyModal from "../../../../ss-dev/slide57/AssemblyModal";
import { ClaimIdentificationLocalisationWithTranslation } from "../../../../ma-dev/Localisation/ClaimIdentificationLocalisationWithTranslation";
import { NonClaimIdentificationLocalisationWithTranslation } from "../../../../ma-dev/Localisation/NonClaimIdentificationLocalisationWithTranslation";
import Asset from "../../../../ss-dev/slide45/Asset";

const stepsData = [
  { id: 0, label: "Localisation with translation" },
  { id: 1, label: "Equivalent Claims Translated" },
  { id: 2, label: "Localised reusable text (Non-claims) translation" },
  { id: 3, label: "Reusing Localised Images" },
  { id: 4, label: "Assemble transformed elements for personalise (with translation)" },
];

export const LocalisationWithTranslationLoaders = () => {
  const [openedModalId, setOpenedModalId] = useState(0);

  return (
    <>
      <StepWiseProgress steps={stepsData} viewButtons={true} setOpenedModalId={setOpenedModalId} />
      <ClaimIdentificationLocalisationWithTranslation
        open={openedModalId === 1}
        setOpen={setOpenedModalId}
      />
      <NonClaimIdentificationLocalisationWithTranslation
        open={openedModalId === 2}
        setOpen={setOpenedModalId}
      />
      <ImageModal open={openedModalId === 3} setOpen={setOpenedModalId} />
      <Asset open={openedModalId === 4} setOpen={setOpenedModalId} withTranslation={true} />
    </>
  );
};
