import { useState } from "react";
import { StepWiseProgress } from "../../components/Conversation/Loaders/StepWiseProgress";
import Component from "../../../../ss-dev/slide55/PersonalisationModal";
import LocalisedClaimModal from "../../../../ss-dev/slide52/LocalisedClaimModal";
import LocalisedNonClaimModal from "../../../../ss-dev/slide52/LocalisedNonClaimModal";
import AssemblyModal from "../../../../ss-dev/slide57/AssemblyModal";

const stepsData = [
  { id: 0, label: "Personalisation without translation and localisations" },
  { id: 1, label: "Equivalent Claims Personalised" },
  { id: 2, label: "Personalised reusable text (Non-claims) generated" },
  { id: 3, label: "Personalised Image/Graphic component" },
  { id: 4, label: "Assembling transformed elements for personalisation (without translation)" },
];

export const PersonalisationWithoutTranslationLocalisation = () => {
  const [openedModalId, setOpenedModalId] = useState(0);

  return (
    <>
      <StepWiseProgress steps={stepsData} viewButtons={true} setOpenedModalId={setOpenedModalId} />
      <LocalisedClaimModal open={openedModalId === 1} setOpen={setOpenedModalId} />
      <LocalisedNonClaimModal open={openedModalId === 2} setOpen={setOpenedModalId} />
      {/* Images */}
      <Component open={openedModalId === 3} setOpen={setOpenedModalId} />
      <AssemblyModal open={openedModalId === 4} setOpen={setOpenedModalId} />
    </>
  );
};
