import { Collapse, Image } from "antd";
import React from "react";
import { CustomPanelHeader } from "../Slide31/CustomHeader";

export const ImageCardCollapse: React.FC<{
  id: string;
  tag: string;
  imgUrl: string;
  title?: string;
}> = ({ id = "", tag, imgUrl, title }) => {
  const items = [
    {
      key: "1",
      label: <CustomPanelHeader id={id} tag={tag} title={title} />,
      children: <Image src={imgUrl} height={200} preview={false} />,
    },
  ];
  return (
    <div
      style={{
        border: "1px solid lightGray",
        borderRadius: "10px",
      }}
    >
      <Collapse
        items={items}
        expandIconPosition="end"
        ghost
        activeKey={["1"]}
        style={{
          textAlign: "center",
        }}
      />
    </div>
  );
};
