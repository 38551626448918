import { Checkbox, Col, Row, Skeleton, Tooltip } from "antd";
import "./MLRSubmission.css";
import { mockData } from "./mockData";
import { useEffect, useRef, useState } from "react";
import { AssetItem } from "../../resources/types";
import {
  getLocalisedAssets,
  getPersonalisedAssets,
  getPersonalisedTranslatedAssets,
  getTranslatedAssets,
} from "../../../../services/contentReplicatorServices";
import { useAppDispatch } from "../../../../store/hooks";
import { setCurrentStepLoading } from "../../../../store/slices/contentReplicatorSlice";
import { MLRSubmissionFooter } from "./MLRSubmissionFooter";
import { ExpandAltOutlined } from "@ant-design/icons";
import { AssetViewer } from "../../components/common/AssetViewer/AssetViewer";

const listKeyMapping = {
  localisation: "localisation",
  localisationWithTranslation: "localisationWithTranslation",
  personalisation: "personalisation",
  personalisationWithTranslation: "personalisationWithTranslation",
};

const listKeyTextMapping = {
  [listKeyMapping.localisation]: "Localisation",
  [listKeyMapping.localisationWithTranslation]: "Translation",
  [listKeyMapping.personalisation]: "Personalisation",
  [listKeyMapping.personalisationWithTranslation]: "Personalisation with Translation",
};

const getMethodsMapping = {
  [listKeyMapping.localisation]: getLocalisedAssets,
  [listKeyMapping.localisationWithTranslation]: getTranslatedAssets,
  [listKeyMapping.personalisation]: getPersonalisedAssets,
  [listKeyMapping.personalisationWithTranslation]: getPersonalisedTranslatedAssets,
};

const initialAssetsList = Object.keys(listKeyMapping).reduce(
  (acc, curr) => {
    acc[listKeyMapping[curr]] = mockData[curr]; //[];
    return acc;
  },
  {} as { [key in keyof typeof listKeyMapping]: AssetItem[] },
);
const initialSelectedItemIds = Object.keys(listKeyMapping).reduce(
  (acc, curr) => {
    acc[listKeyMapping[curr]] = [];
    return acc;
  },
  {} as { [key in keyof typeof listKeyMapping]: string[] },
);

export const MLRSubmission = () => {
  const [assetsList, setAssetsList] = useState<{
    [key in keyof typeof listKeyMapping]: AssetItem[];
  }>(
    /* {
    localisation: localisationItemsList || [],
    localisationWithTranslation: [],
    personalisation: [],
    personalisationWithTranslation: [],
  } */ initialAssetsList,
  );
  const [selectedItemIds, setSelectedItemIds] =
    useState<{ [key in string]: string[] }>(initialSelectedItemIds);

  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [previewItem, setPreviewItem] = useState<AssetItem>();
  const currentElRef = useRef<HTMLDivElement>();
  const dispatch = useAppDispatch();

  console.log(assetsList);
  console.log(selectedItemIds);
  //   useEffect(() => {
  //     (async () => {
  //       const res = (await getLocalisedAssets("1adFwef")) as AssetItem[];
  //       if (!res) return;

  //       console.log(res);
  //       setAssetsList(prevList => {
  //         return {
  //           ...prevList,
  //           localisation: res,
  //         };
  //       });
  //     })();
  //   }, []);

  useEffect(() => {
    dispatch(setCurrentStepLoading(true));
  }, []);

  useEffect(() => {
    if (currentElRef.current) {
      setTimeout(() => {
        currentElRef.current &&
          currentElRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 100);
    }
  }, [currentElRef.current, loader]);

  useEffect(() => {
    if (Object.keys(selectedItemIds).some(key => selectedItemIds[key].length > 0)) {
      dispatch(setCurrentStepLoading(false));
    } else dispatch(setCurrentStepLoading(true));
  }, [selectedItemIds]);

  useEffect(() => {
    (async () => {
      const promises = Object.keys(listKeyMapping).map(async key => {
        const res = await getMethodsMapping[key]("1adFwef");
        if (!res) return;

        console.log(res);
        return {
          [listKeyMapping[key]]: res,
        };
      });

      setLoader(true);

      const results = await Promise.all(promises);

      //@ts-ignore
      setAssetsList(prevList => {
        return results.reduce((acc, curr) => {
          return {
            ...acc,
            ...curr,
          };
        }, prevList);
      });

      setLoader(false);
    })();
  }, []);

  const handleSelectDeselect = (type: string, id: string) => {
    if (selectedItemIds[type].includes(id)) {
      setSelectedItemIds({
        ...selectedItemIds,
        [type]: selectedItemIds[type].filter(item => item !== id),
      });
    } else {
      setSelectedItemIds({
        ...selectedItemIds,
        [type]: [...selectedItemIds[type], id],
      });
    }
  };

  const handleSelectDeselectAll = (type: string) =>
    setSelectedItemIds({
      ...selectedItemIds,
      [type]: isAllSelected(type) ? [] : assetsList[type].map(({ asset_id }) => asset_id),
    });

  const handleReset = () => {
    setSelectedItemIds({
      localisation: [],
      localisationWithTranslation: [],
      personalisation: [],
      personalisationWithTranslation: [],
    });
  };

  const isSelected = (type: string, id: string) => {
    return selectedItemIds[type].includes(id);
  };

  const isAllSelected = (type: string) => {
    return selectedItemIds[type].length === assetsList[type].length;
  };

  const getSelectedAssets = () => {
    return Object.keys(listKeyMapping).reduce((acc, curr) => {
      acc.push(
        ...assetsList[curr].filter((asset: AssetItem) =>
          selectedItemIds[curr].includes(asset.asset_id),
        ),
      );
      return acc;
    }, [] as AssetItem[]);
  };

  const downloadAllPDFAssets = async () => {
    console.log("downloading all pdf assets");
    Object.keys(listKeyMapping).forEach(key => {
      if (selectedItemIds[key].length > 0) {
        selectedItemIds[key].forEach(async assetId => {
          const asset = assetsList[key].find(asset => asset.asset_id === assetId);

          if (asset) {
            try {
              const response = await fetch(asset.url);
              const blob = await response.blob();

              const link = document.createElement("a");
              const url = window.URL.createObjectURL(blob);
              link.href = url;
              link.setAttribute("download", `${asset.asset_id}.pdf`);

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              window.URL.revokeObjectURL(url);
            } catch (error) {
              console.error("Error downloading the file:", error);
            }
          }
        });
      }
    });
  };

  const getAssetsOfTypeOfPreviewItem = () => {
    return assetsList[getPreviewedItemType()];
  };

  const getPreviewedItemType = () => {
    if (!previewItem) return listKeyMapping.localisation; //Return first key if no item is selected for preview

    const assetKey = Object.keys(listKeyMapping).find(k =>
      assetsList[listKeyMapping[k]].find(
        (asset: AssetItem) => asset.asset_id === previewItem?.asset_id,
      ),
    );
    return assetKey;
  };

  return (
    <div className="mlr-submission" ref={currentElRef}>
      <div>
        <span style={{ fontWeight: "bold" }}>Market: </span>
        <span>Spain</span>
      </div>
      {!loader ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            background: "#f5f5f5",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          {Object.keys(listKeyMapping).map((key, index) => (
            <div className="list-container">
              <div className="list-header">
                <Checkbox
                  checked={isAllSelected(key)}
                  indeterminate={selectedItemIds[key].length > 0 && !isAllSelected(key)}
                  onChange={() => handleSelectDeselectAll(key)}
                  className="select-all-checkbox"
                />{" "}
                {listKeyTextMapping[key]}{" "}
                <span className="count">
                  ({selectedItemIds[key].length}/{assetsList[key].length})
                </span>
              </div>
              <Row className="items-list" gutter={[8, 8]} wrap={false}>
                {assetsList[key].map((item: AssetItem, index: number) => (
                  <Col span={6} key={index}>
                    <div
                      className="image-container"
                      onClick={() => handleSelectDeselect(key, item.asset_id)}
                    >
                      <Checkbox
                        checked={selectedItemIds[key].includes(item.asset_id)}
                        onClick={e => e.stopPropagation()}
                        onChange={() => handleSelectDeselect(key, item.asset_id)}
                        className="checkbox-overlay"
                      />
                      <img
                        src={item.thumbnail}
                        className={`item ${isSelected(key, item.asset_id) ? "selected-item" : ""}`}
                      />
                      <div className="item-name">{item.asset_id}</div>
                      <Tooltip title="Expand">
                        <ExpandAltOutlined
                          style={{
                            position: "absolute",
                            top: 10,
                            right: 10,
                            fontSize: "18px",
                            color: "#fff",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            borderRadius: "50%",
                            padding: "3px",
                            cursor: "pointer",
                          }}
                          onClick={e => {
                            e.stopPropagation();
                            setPreviewItem(item);
                            setOpenModal(true);
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          ))}
          <MLRSubmissionFooter onDownloadClick={downloadAllPDFAssets} />
          <AssetViewer
            title={listKeyTextMapping[getPreviewedItemType()]}
            open={openModal}
            setOpen={setOpenModal}
            item={previewItem}
            assetsList={getAssetsOfTypeOfPreviewItem()}
          />
        </div>
      ) : (
        <Skeleton active />
      )}
    </div>
  );
};
