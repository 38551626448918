import React from "react";
import { Card, Row, Col } from "antd";
// import { BulbOutlined, FileTextOutlined, ExperimentOutlined } from '@ant-design/icons';
import dynamic_feed from "../assets/svg/Frontpage/dynamic_feed.svg";
import Edit from "../assets/svg/Frontpage/edit.svg";
import Account from "../assets/svg/Frontpage/account_circle.svg";
import Psychology from "../assets/svg/Frontpage/psychology.svg";
import Obj from "../assets/svg/Frontpage/emoji_objects.svg";
import { useNavigate } from "react-router-dom";
import ROUTE_PATHS from "../../routes/routePaths";

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  index: number;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ icon, title, description, index }) => {
  const navigate = useNavigate();

  return (
    <Card
      style={{
        width: "100%",
        height: "150px", // Adjusted height for each card
        borderRadius: "12px",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        background: "#fff",
        display: "flex",
        flexDirection: "row", // Changed to row to align icon and text horizontally
        alignItems: "center",
        // padding: 'px', // Adjusted padding
        cursor: "pointer ",
      }}
      onClick={() => index === 0 && navigate(ROUTE_PATHS.CONTENT_REPLICATOR)}
    >
      <div
        style={{
          backgroundColor: "#F3E8FF",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 16px 14px 0", // Adjust margin to align left
        }}
      >
        {icon}
      </div>
      <div>
        <h3 style={{ fontSize: "18px", marginBottom: "4px" }}>{title}</h3>
        <p style={{ color: "#8c8c8c", fontSize: "14px", margin: 0 }}>{description}</p>
      </div>
    </Card>
  );
};

const cardData = [
  {
    icon: <img src={dynamic_feed} style={{ fontSize: "24px", color: "#722ed1" }} />,
    title: "Content Replicator",
    description: "Effortlessly replicates content across markets",
  },
  {
    icon: <img src={Edit} style={{ fontSize: "24px", color: "#722ed1" }} />,
    title: "Content Al Studio",
    description: "Instructs to create compliant content",
  },
  {
    icon: <img src={Account} style={{ fontSize: "24px", color: "#722ed1" }} />,
    title: "Content Transcreation",
    description: "Repurposes content across asset types",
  },
  {
    icon: <img src={Psychology} style={{ fontSize: "24px", color: "#722ed1" }} />,
    title: "Brand IQ",
    description: "Derives actionable brand insights",
  },
  {
    icon: <img src={Edit} style={{ fontSize: "24px", color: "#722ed1" }} />,
    title: "Creative AI Lab",
    description: "One-stop solution for all creative needs",
  },
  {
    icon: <img src={Obj} style={{ fontSize: "24px", color: "#722ed1" }} />,
    title: "Content Production",
    description: "HTML-izes content with the click of a button",
  },
];

const FeatureCards: React.FC = () => (
  <div style={{ padding: "24px 50px" }}>
    {" "}
    {/* Added padding for left and right */}
    <Row gutter={[24, 24]} justify="center">
      {cardData.map((card, index) => (
        <Col key={index} xs={24} sm={24} md={8}>
          <FeatureCard
            icon={card.icon}
            title={card.title}
            description={card.description}
            index={index}
          />
        </Col>
      ))}
    </Row>
  </div>
);

export default FeatureCards;
