import React from "react";
import { Typography } from "antd";

const { Title, Paragraph } = Typography;

const ReplicatorAIWelcome: React.FC = () => {
  const containerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    width: "100%", // Allow the container to take full width
    maxWidth: "1200px", // Increased maxWidth for larger width
    margin: "0 auto",
  };

  const gradientStyle: React.CSSProperties = {
    width: "100%",
    height: "4px",
    background: "linear-gradient(90deg, #ff00ff, #00ffff)",
    marginBottom: "20px",
  };

  const titleStyle: React.CSSProperties = {
    fontSize: "34px", // Increased font size
    fontWeight: "bold",
    marginBottom: "16px",
    textAlign: "center",
    width: "100%",
  };

  const subtitleStyle: React.CSSProperties = {
    fontSize: "20px", // Slightly larger subtitle text
    color: "#666",
    textAlign: "center",
    width: "100%",
  };

  return (
    <div style={containerStyle}>
      {/* <div style={gradientStyle} aria-hidden="true" /> */}
      <Title level={1} style={titleStyle}>
        <span role="img" aria-label="Waving hand">
          👋
        </span>{" "}
        Hey! Welcome to Replicator.AI solution.
      </Title>
      <Paragraph style={subtitleStyle}>
        I am Rep.AI to help you with all your campaign content replication needs.
        <br />I can help you with:
      </Paragraph>
    </div>
  );
};

export default ReplicatorAIWelcome;
