import React from "react";
import { Modal, Switch, Button, Typography, Space, Row, Col } from "antd";
import { CloseOutlined, CheckCircleFilled } from "@ant-design/icons";
import VVPM from "../component/Tags/VVPM";
import Precentage from "../component/Tags/Precentage";
import AIGeneratedTag from "../component/Tags/AIGeneratedTag";
// import DummyPdf from '../assets/test.pdf';
import { Document, Page, pdfjs } from "react-pdf";
import axios from "axios";
import { PDFViewer } from "../../pages/Content Replicator/components/pdf/PDFViewer";
import { useSyncedScroll } from "../../hooks/useSyncedScroll";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const { Title, Text } = Typography;

interface LocalizedAssetModalProps {
  open: boolean;
  setOpen?: Function;
  withTranslation?: boolean;
}

const AssemblyModal: React.FC<LocalizedAssetModalProps> = ({
  open,
  setOpen,
  withTranslation = false,
}) => {
  const [selectedImage, setSelectedImage] = React.useState<number>(0);
  const { firstRef, secondRef } = useSyncedScroll(open);

  const onCancel = () => {
    setOpen?.(false);
  };
  const onSave = () => {
    setOpen?.(false);
  };
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<PAsset[]>([]);

  React.useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let api: string;
        if (withTranslation) {
          api = "https://replicator-dev.devkraft.in/api/personalise/1adFwef?language=spanish";
        } else {
          api = "https://replicator-dev.devkraft.in/api/personalise/1adFwef?language=english";
        }

        const response = await axios.get(api);
        console.log(response.data.data);
        const fetchedData: PAsset[] = response?.data?.data || [];
        console.log("line number 57", fetchedData);
        setData(fetchedData);
        setSelectedImage(0);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      width={1300}
      style={{ top: 20 }}
      closeIcon={<CloseOutlined style={{ fontSize: "16px", color: "#000" }} />}
    >
      <div style={{ padding: "20px" }}>
        <Title level={3} style={{ marginBottom: "20px" }}>
          Assembly:
          <span>
            <Text
              style={{
                fontSize: "22px",
                fontWeight: 400,
              }}
            >
              {withTranslation
                ? "Localised Asset With Translation and Personalisation"
                : "Localised Asset Without Translation and Personalisation"}
            </Text>
          </span>
        </Title>
        <Row gutter={16}>
          <Col span={10}>
            <div
              style={{
                backgroundColor: "#E8EAF0",
                padding: "16px",
                borderRadius: "8px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Row style={{ marginBottom: "1rem" }}>
                <Text>Original Asset</Text>
              </Row>
              <div
                style={{
                  height: "600px",
                  overflow: "auto",
                }}
                ref={firstRef}
              >
                {/* <Document
                  file={{
                    url: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/RTE_US_English.pdf",
                  }}
                >
                  <Page pageNumber={1} />
                </Document> */}
                <PDFViewer url="https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/RTE_US_English.pdf" />
              </div>
            </div>
          </Col>
          <Col span={10}>
            <div
              style={{
                padding: "16px",
                borderRadius: "8px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Row style={{ marginBottom: "1rem" }}>
                <Text>
                  Personalised Assets:{" "}
                  <span style={{ fontWeight: 500 }}>{data?.[selectedImage]?.persona}</span>{" "}
                </Text>
              </Row>
              {/* <img
                src={selectedImage}
                alt="Personalized Asset"
                style={{
                  width: "100%",
                  height: "460px",
                  objectFit: "cover",
                  borderRadius: 4,
                  cursor: "pointer",
                }}
              /> */}
              <div
                style={{
                  height: "600px",
                  overflow: "auto",
                }}
                ref={secondRef}
              >
                {/* <Document
                  file={{
                    url: data?.[selectedImage]?.url,
                  }}
                >
                  <Page pageNumber={1} />
                </Document> */}
                <PDFViewer url={data?.[selectedImage]?.url} />
              </div>
              {withTranslation && (
                <>
                  <Text>The Evidence-Based Physician</Text>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <div style={{ display: "flex", columnGap: "1rem" }}>
                      <AIGeneratedTag />
                      <Precentage percentage={98} text="Match Rate" />
                    </div>
                    <div>
                      <Button
                        type="primary"
                        style={{
                          width: "100%",
                          backgroundColor: "white",
                          borderColor: "#6B46C1",
                          color: "#6B46C1",
                          fontWeight: "bold",
                          height: "35px",
                          borderRadius: "8px",
                        }}
                      >
                        Show More
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Col>

          <Col span={4}>
            {/* {withTranslation && (
              <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                  <Text>Show Translated</Text>
                  <Switch defaultChecked style={{ marginLeft: "10px" }} />
                </div>
              </Row>
            )} */}
            <div style={{ overflowY: "auto", maxHeight: "90vh", paddingRight: 8 }}>
              <Row gutter={[0, 16]} style={{ flexDirection: "column" }}>
                {data?.map((thumbnail, index) => (
                  <>
                    <Col key={index} span={24} style={{ flex: "0 0 auto", position: "relative" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{}}>
                          {thumbnail?.type === "AI Generated" ? <AIGeneratedTag /> : <VVPM />}
                        </div>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              backgroundColor: "white",
                              borderRadius: "10px",
                              width: "fit-content",
                              // padding: "0.45rem",
                            }}
                          >
                            <div
                              style={{
                                color: "#48BB78",
                                fontWeight: "bold",
                                marginRight: "4px",
                                fontSize: "8px",
                              }}
                            >
                              {98}%
                            </div>
                            <div style={{ color: "#718096", fontSize: "8px" }}>{"Match rate"}</div>
                          </div>
                        </div>
                      </div>

                      <div style={{ position: "relative" }}>
                        <img
                          src={thumbnail?.thumbnail}
                          alt={`Thumbnail ${index + 1}`}
                          style={{
                            width: "100%",
                            height: "150px",
                            objectFit: "cover",
                            borderRadius: 4,
                            cursor: "pointer",
                          }}
                          onClick={() => setSelectedImage(index)}
                        />
                        {selectedImage === index && (
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white
                              border: "2px solid #6752e9", // Blue border
                              borderRadius: 4,
                              boxSizing: "border-box",
                            }}
                          >
                            <CheckCircleFilled
                              style={{
                                position: "absolute",
                                bottom: 4,
                                right: 4,
                                color: "#6752e9",
                                fontSize: 24, // Adjust size as needed
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                  </>
                ))}
              </Row>
            </div>
          </Col>
        </Row>
        <Space style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button type="primary" onClick={onSave}>
            Save
          </Button>
        </Space>
      </div>
    </Modal>
  );
};

export default AssemblyModal;
