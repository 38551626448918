import React from "react";
import ContentCard from "./ContentCard";
import ReplicatorAIWelcome from "./ReplicatorAIWelcome";
import GetStartedButton from "./GetStartedButton";
import logo from "../assets/svg/logo.svg";

type Props = {};

const Index: React.FC<{ onStart: () => void }> = ({ onStart }) => {
  const containerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: "20px",
    width: "100%",
    transform: "scale(0.85)",
  };

  const logoStyle: React.CSSProperties = {
    width: "50px",
    marginBottom: "20px",
  };

  return (
    <div style={containerStyle}>
      <img src={logo} style={logoStyle} className="App-logo" alt="logo" />
      <ReplicatorAIWelcome />
      <ContentCard />
      <div style={{ marginTop: "20px" }}>
        <GetStartedButton onClick={onStart} />
      </div>
    </div>
  );
};

export default Index;
