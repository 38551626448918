export const ASSETS = {
  APP_LOGO: "/assets/icons/IntelReg.svg",
  HEADER_NOTIFICATION: "/assets/icons/NotificationIcon.svg",
  HEADER_AVATAR: "/assets/icons/HeaderAvatarImage.png",
  SIDEBAR_CONTENT_REPLICATOR_ICON: "/assets/icons/sidebar/ContentReplicatorIcon.svg",
  SIDEBAR_REPORTS_ICON: "/assets/icons/sidebar/ReportsIcon.svg",
  SIDEBAR_REARRANGE_ICON: "/assets/icons/sidebar/RearrangeIcon.svg",
  SIDEBAR_SETTINGS_ICON: "/assets/icons/sidebar/SettingsIcon.svg",
  CONTENT_REPLICATOR_MODULE_ICON: "/assets/icons/content-replicator/DynamicFeed.svg",
  CR_ADMIN_NEXT_ICON: "/assets/icons/content-replicator/AdminNextIcon.svg",
  CR_EDIT_MESSAGE_ICON: "/assets/icons/content-replicator/EditMessageIcon.svg",
  CR_PIN_MESSAGE_ICON: "/assets/icons/content-replicator/PinMessageIcon.svg",
  CR_USER_AVATAR_ICON: "/assets/icons/content-replicator/UserAvatarIcon.png",
};
